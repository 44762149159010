@import "~styles/colors";
@import "~styles/typography";

.message {
  transition: 1s;

  .messageBody {
    display: flex;
    margin: 5px 0;

    .messageAvatar {
      width: 45px;
      height: 45px;
    }

    .replyImg {
      background-color: $greyShade3;
      cursor: pointer;

      & > img {
        width: initial !important;
        height: initial !important;
      }
    }

    .messageText {
      max-width: 556.67px;
      padding: 26px 49px 26px 23px;
      border-radius: 15px;
      background-color: $greyShade3;
      margin: 0 20px;

      &span {
        @include font($fontAeonik, 18.67px, 28px, 400);
        color: $ternaryTextColor;
      }
    }

    .myMessageText {
    }
  }

  .myMessage {
    flex-direction: row-reverse;

    .messageText {
      background-color: $primaryColor;
      color: $greyShade4;
    }
  }
}

.selectedMessage {
  background-color: $greenBackgroundColor !important;
}

.searchedMessage {
  background-color: $primarySubColor !important;
}
