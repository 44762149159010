@import "~styles/colors";

.profileAvatarWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .avatarBlock {
    width: 107px;
    height: 107px;
    border-radius: 50%;
    background-color: $greyShade2;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    label {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      background-color: $blueBackgroundColor;
      color: $greyShade4;
    }

    input {
      position: absolute;
      top: -50px;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 10;
      outline: none;
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .name {
    text-align: center;
    color: $ternaryTextColor;
    margin: 23px 0 55px 0;
  }
}
