@import "~styles/colors";
@import "~styles/typography";

.input-container {
  width: 100%;
  min-height: 65px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  position: relative;
  border-bottom: 1px solid #555555;
  cursor: pointer;

  .input-wrap {
    position: relative;
  }

  .password-eye-wrapper {
    display: none;
    position: absolute;
    right: 8px;
    top: 4px;
    line-height: 0;

    .password-eye {
      padding: 0;
      line-height: 14px;
      background: none;
      border: none;
      outline: none;

      &:hover,
      &:active,
      &:focus,
      &:active:focus {
        background-color: transparent;
        box-shadow: none;
      }

      //&.visible {
      //  svg {
      //    path {
      //      stroke: $primaryTextColor;
      //    }
      //  }
      //}
    }
  }
}

.input-label {
  position: absolute;
  top: 20px;
  text-align: left;
  @include font($fontAeonik, 24px, 30px, normal);
  color: $inputLabelColor !important;
  margin-bottom: 0;
  z-index: 1;
  transition: all 0.3s;
  pointer-events: none;

  text-overflow: ellipsis;
  overflow: hidden;
  width: 90%;
  white-space: nowrap;

  &-active {
    top: -13px;
    @include font($fontAeonik, 13px, 16px, normal);
  }
}

.error {
  position: absolute;
  top: -5px;
  left: 0;
  width: max-content;
  z-index: 1;
  color: $alertColor;
}

.input {
  width: 100%;
  height: auto !important;
  background: $greyShade4 !important;
  position: relative;
  padding: 20px 0 16px !important;
  border: none !important;
  @include font($fontAeonik, 24px, 24px, normal);
  font-size: 24px !important;
  line-height: 24px !important;
  color: $primaryTextColor !important;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px white inset !important;
  }

  &:focus,
  &:active {
    outline: none !important;
    box-shadow: none !important;
  }
}
