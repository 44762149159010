.colorInput {
  height: 40px;
  width: 80px;
  border-radius: 5px;
  display: block;
  margin: 0;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;

  &::-webkit-color-swatch {
    border-radius: 5px;
    border: none;
  }
}
