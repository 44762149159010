@import "~styles/colors";
@import "~styles/typography";

.auth-background {
  width: 100%;
  min-height: 100vh;
  padding: 50px 0;
  background-image: url("~assets/images/auth-bg.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;

  .auth-container {
    width: 638px;
    // min-height: 850px;
    border-radius: 15px;
    background: $greyShade4;
    margin-left: 274px;
    padding: 109px 91px 89px 92px;

    .title {
      font-size: 64px;
    }

    .form {
      margin-top: 84px;
    }

    .buttonContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        color: #7d7d7d;
        text-decoration: underline;
      }

      .signBtn {
        width: 100%;
        max-width: 160px;
        height: 66.67px;
        border-radius: 33.33px !important;
        line-height: initial !important;
      }
    }

    .socialContainer {
      @include font($fontAeonik, 21px, 21px, normal);
      margin-top: 53px;
      color: #7d7d7d;

      .socialButton {
        width: 80px;
        height: 80px;
        margin-left: 16px;
        background: #fff !important;
        color: #000;
        border: 2px solid #e0e0e0 !important;
        border-radius: 50% !important;
        padding: 0 !important;

        &:hover {
          background: #f3f3f3 !important;
        }
      }
    }

    .altActionContainer {
      @include font($fontAeonik, 21px, 21px, normal);
      margin-top: 93px;
      color: #000;

      a {
        color: $primaryColor;
        text-decoration: underline;
      }
    }
  }
}
