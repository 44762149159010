@import "~styles/colors";
@import "~styles/typography";

.modalContainer {
  max-width: 960px !important;
}

.modalContent {
  border-radius: 15px !important;
  padding: 39px 74px;
}

.header {
  display: flex;
  justify-content: space-between;

  .closeBtn {
    width: 53px;
    height: 53px;
    transform: rotate(45deg);
  }

  .titleBlock {
    .title {
      @include font($fontAeonik, 24px, 45.49px, 800);
      color: $greyShade1;
    }

    .subtitle {
      @include font($fontAeonik, 18.67px, 21.33px, 400);
      color: $greyShade2;
      margin-bottom: 23px;
    }
  }
}

.modalBody {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.platformsRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 24px 0;

  .infoContainer {
    display: flex;
    @include font($fontAeonik, 16px, 21px, 400);
    color: $ternaryTextColor;
    margin-right: 25px;

    & div:first-child {
      margin-right: 15px;
    }
  }

  .platformsContainer {
    display: flex;

    .platform {
      display: flex;
      align-items: center;
      margin-right: 25px;

      .platformColor {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        margin-right: 7px;
        background-color: red;
        flex-shrink: 0;
      }

      .platformName {
        @include font($fontAeonik, 16px, 21px, 500);
        color: $greyText;
      }
    }
  }
}

.listingBlock {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;

  .listingName {
    @include font($fontAeonik, 32px, 64px, 600);
  }
}

.characteristicsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 70px;
  margin-top: 20px;
}

.fieldContainer {
  position: relative;

  .supportContainer {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;

    .platform {
      width: 10.66px;
      height: 10.66px;
      margin-left: 6px;
      transform: rotate(45deg);
    }

    .required {
      border-radius: 50%;
    }

    .optional {
      border-radius: 50%;
    }

    .notAppear {
      border-radius: 50%;
    }

    .notAllowed {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.buttonsBlock {
  display: flex;
  justify-content: center;
  margin-top: 51px;
}

.selectBtn {
  width: 260px;
  height: 55px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 26.67px !important;
  border: 1px solid $borderGreyColor !important;

  &:hover {
    border: 1px solid transparent !important;
    border-color: transparent !important;
  }

  .select {
    width: 120%;
    margin-bottom: 0;
    border: none !important;

    & svg {
      margin: 0;
    }
  }
}

.btn {
  width: 100%;
  max-width: 222px;
  height: 66.67px;
  border-radius: 33.33px !important;
}

.saveBtn {
  margin-right: 21px;
}

// Legend
.legendContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .legendBlock {
    margin-bottom: 30px;

    .legend {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $blueBackgroundColor;
      margin-bottom: 10px;
    }

    .legendOptional {
      border: 3px solid $blueBackgroundColor;
      background-color: $primaryBackgroundColor;
    }

    .legendNotAppear {
      border: 1.5px solid $greyShade2;
      background-color: $primaryBackgroundColor;
    }

    .legendNotAllowed {
      background-color: $primaryBackgroundColor;
      transform: rotate(45deg);
    }

    .legendText {
      color: $greyTextColor;
    }
  }
}

.legendCategoryFieldsText {
  line-height: 1.2rem !important;
  color: $greyTextColor;
}
