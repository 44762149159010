@import "~styles/colors";
@import "~styles/typography";

.switcherBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  .statusTitle {
    @include font($fontAeonik, 18.67px, 40px, 500);
    color: $blueTextColor;
  }
}

/* The switcher */
.switcherContainer {
  display: flex;
  justify-content: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 31px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $borderGreyColor;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 4px;
  bottom: 3px;
  background-color: $primaryBackgroundColor;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $blueBackgroundColor;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primaryBackgroundColor;
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
  background-color: $primaryBackgroundColor;
}

.slider.round {
  border-radius: 15.46px;
}

.slider.round:before {
  border-radius: 50%;
}

.platformSwitcher {
  max-width: 400px;
  border: 1px solid $borderGreyColor;
  border-radius: 15px;
  padding: 17px 34px 17px 27px;
  margin-bottom: 7px;
}

.infoBlock {
  display: flex;

  .imgContainer {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    margin-right: 12px;

    & > img {
      width: 100%;
    }
  }
}

.disabledPlatform {
  .imgContainer {
    & > img {
      fill: $greyShade2;
      transition: 0.5s;
    }
  }

  .platformName {
    color: $greyTextColor;
    transition: 0.5s;
  }
}
