@import "~styles/colors";
@import "~styles/typography";

.selectsContainer {
  display: flex;
  justify-content: space-between;
  column-gap: 40px;

  .selectSendBlock {
    width: 515px;
    margin: 0;
  }

  .selectAmountBlock {
    width: 230px;
    margin: 0;
  }

  .selectUnitsBlock {
    width: 230px;
    margin: 0;
  }
}
