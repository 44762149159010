$e-sm: 575.98px;
$sm: 767.98px;
$md: 991.98px;
$lg: 1199.98px;
$e-lg: 1399.98px;

@mixin for-phone-only {
  @media (max-width: $e-sm) {
    @content;
  }
}

@mixin for-tablet-portrait {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin for-tablet-landscape {
  @media (max-width: $md) {
    @content;
  }
}

@mixin for-desktop {
  @media (max-width: $lg) {
    @content;
  }
}

@mixin for-lg-desktop {
  @media (max-width: $e-lg) {
    @content;
  }
}
