@import "~styles/colors";
@import "~styles/typography";

.modalContainer {
  max-width: 590px !important;
}

.modalContent {
  padding: 0 45px 72px;
  border-radius: 15px !important;
}

.header {
  display: flex;
  justify-content: center;
  padding: 52px 0 27px;
}

.modalBody {
  padding: 0;
}

.channel {
  display: flex;
  align-items: center;
  padding: 26px 0;
  margin-bottom: 20px;

  .channelLogo {
    width: 43.33px;
    height: 43.33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;

    & img {
      width: 100%;
      height: 100%;
    }
  }
  .channelName {
    padding-left: 20px;
    margin-right: 10px;
    @include font($fontAeonik, 21.33px, 21.33px, 400);
  }
  .channelAddBtn {
    width: 43.33px;
    height: 43.33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid $borderGreyColor !important;
    margin-left: auto;

    &:hover {
      border: none !important;
    }
  }

  .loader {
    margin: 0;
    margin-right: 25px;
    margin-left: auto;
  }

  .channelConnectBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 23.33px;
    margin-left: auto;
    border: 3px solid $borderGreyColor !important;
    @include font($fontAeonik, 16px, 16px, 600);

    &:hover {
      border: none !important;
    }
  }

  & > a {
    margin-left: auto;
  }
}

.disconnected {
  color: $greyShade2;

  .channelLogo {
    & img {
      filter: saturate(0);
    }
  }
}

.loader {
  margin: 0;
  margin-right: 25px;
  margin-left: auto;
}
