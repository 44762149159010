
@import "~styles/fonts/Aeonik/stylesheet.css";
@import "~styles/fonts/Tungsten/stylesheet.css";

$fontAeonik: Aeonik;
$fontAeonikLight: Aeonik;
$fontTungsten: Tungsten;
$fontTungstenBook: 'Tungsten Book';

@mixin font(
  $fontFamily,
  $fontSize,
  $lineHeight,
  $fontWeight: normal,
  $fontStyle: normal
) {
  font-family: $fontFamily;
  font-style: $fontStyle;
  font-weight: $fontWeight;
  font-size: $fontSize;
  line-height: $lineHeight;
}
