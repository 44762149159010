@import "~styles/colors";
@import "~styles/typography";

.modalContainer {
  max-width: 900px !important;

  .modalContent {
    overflow: hidden;
    border-radius: 0.5rem !important;

    .modalBody {
      display: flex;

      .leftSide {
        width: 50%;
        background-image: url("~assets/images/auth-bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
      }

      .rightSide {
        width: 50%;
        padding: 70px 50px;

        .title {
          @include font($fontAeonik, 34px, 40px, 800);
          color: $ternaryTextColor;
          padding-bottom: 20px;
        }

        .text {
          @include font($fontAeonik, 20px, 30px, 400);
          color: $greyTextColor;
          padding: 40px 0;
        }
      }
    }
  }
}
