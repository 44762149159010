@import "~styles/colors";
@import "~styles/typography";

.stepsProgress {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 50px 0;

  .stepProgress {
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin: 10px 30px 10px 0;

    .stepName {
      padding-left: 27px;
      margin-right: 23px;
      white-space: nowrap;
      @include font($fontAeonik, 18.67px, 18.67px, 500);
      color: $greyTextColor;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 13.33px;
        height: 13.33px;
        border-radius: 50%;
        border: 4px solid $greyShade3;
        background-color: $greyShade4;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
      }
    }

    .stepSeperator {
      width: 100%;
      height: 0.7px;
      background-color: $borderGreyColor;
    }
  }

  .currentStep {
    .stepName {
      color: $ternaryTextColor;

      &::before {
        border: none;
        background-color: $blueBackgroundColor;
        top: 50%;
        transform: translate(0, -50%);
        left: 0;
      }
    }
  }

  .passedStep {
    .stepName {
      &::before {
        border: none;
        background-color: $greenTextColor;
      }
    }
  }

  .activateBtn {
    width: 100%;
    max-width: 207px;
    height: 46.67px;
    border-radius: 23.33px !important;
    border: 2px solid $borderGreyColor !important;
    @include font($fontAeonik, 16px, 16px, 500);
    line-height: initial !important;
    margin-top: auto;

    &:hover {
      border: none !important;
    }

    &:disabled {
      background-color: $primaryBackgroundColor !important;
      color: $greyTextColor;
      pointer-events: none;
    }
  }
}
