@import "~styles/colors";
@import "~styles/typography";

.paymentContainer {
  width: 100%;
  display: flex;

  .planContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid $greyShade3;

    .planWrapper {
      width: 100%;
      max-width: 558px;
      padding-right: 53px;

      .subscribeTo {
        margin-top: 37px;
        @include font($fontAeonik, 19px, 19px, 900);
        color: #7d7d7d;
        text-align: center;
      }

      .planName {
        margin-top: 20px;
        @include font($fontAeonik, 32px, 32px, 900);
        color: $greyShade1;
        text-align: center;
      }

      .limitations {
        margin-top: 39px;
        width: 275px;
        white-space: nowrap;
        margin-left: auto;
        margin-right: auto;

        .checkRow {
          display: flex;
          align-items: center;
          align-items: baseline;
          margin-bottom: 25px;

          &:last-child {
            margin-bottom: 0;
          }

          img {
            margin: 0 13px 0 15px;
          }

          div {
            @include font($fontAeonik, 16px, 21.33px, 400);
            color: $greyTextColor;
          }
        }
      }

      .price {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 4.17in;
        margin-top: 60px;
        border-radius: 15px;
        border: 1px solid #d5d5d5;

        &Value {
          margin-top: 41px;
          display: flex;
          align-items: flex-start;
          @include font($fontTungstenBook, 10px, 160px, normal);
          padding-bottom: 40px;
          border-bottom: 1px solid #d5d5d5;

          &Int {
            font-size: 160px;
          }

          &Fract {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            font-size: 53px;
            line-height: 53px;
            margin-top: 15px;
            margin-left: 15px;
          }
        }
      }

      .perMonth {
        margin-top: 33px;
        @include font($fontAeonik, 19px, 19px, 900);
        color: #7d7d7d;
        text-align: center;
      }
    }

    .poweredBy {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      @include font($fontAeonik, 13px, 13px, normal);
      color: #999999;
      margin-top: 114px;
      flex: 1;

      &Stripe {
        padding-right: 21px;
        border-right: 1px solid #d7d7d7;
      }

      &Terms {
        padding-left: 21px;
      }
    }
  }

  .cardContainer {
    flex: 1;
    display: flex;
    justify-content: center;

    .cardWrapper {
      max-width: 558px;
      padding-left: 53px;
      display: flex;
      flex-direction: column;

      .cardHeader {
        @include font($fontAeonik, 32px, 50px, 900);
        color: #000;
        margin-top: 68px;
        text-align: center;
        margin-bottom: 60px;
      }

      .paymentInput {
        font-size: 19px;
      }

      .cardInput {
        margin-top: 16px;
        height: 50px;
        border-bottom: 1px solid #000;
      }

      .subscribeButton {
        margin: auto;
        width: 2.11in;
        margin-top: 40px;
      }

      .agreement {
        flex: 1;
        display: flex;
        align-items: flex-end;
        @include font($fontAeonik, 13px, 13px, normal);
        color: #999999;
        text-align: center;
        width: 356px;
      }
    }
  }
}
