@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.screenContainer {
  display: flex;

  @include for-desktop {
    flex-wrap: wrap;
  }

  .marketplaceInfo {
    width: 100%;
    max-width: 329px;
    background-color: $primaryBackgroundColor;
    padding: 47px 33px 41px 33px !important;
    margin-right: 27px;
    transition: 0.5s;

    @include for-desktop {
      margin-bottom: 20px;
    }
  }

  .connectChannelWrapper {
    display: flex;
    flex-grow: 2;
    overflow: hidden;
    background-color: $primaryBackgroundColor;
    padding: 53px 82px 80px !important;
    flex-direction: column;

    @include for-lg-desktop {
      padding: 35px;
    }
  }
}

.coInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 35px;

  .coLogo {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;
    background-color: $blueBackgroundColor;
    margin-bottom: 24px;

    & > img {
      width: 100%;
    }
  }

  .coName {
    @include font($fontAeonik, 24px, 24px, 600);
    margin-bottom: 14px;
    color: $ternaryTextColor;
  }

  .coSubtitle {
    @include font($fontAeonik, 16px, 16px, 400);
    color: $greyTextColor;
  }
}

.marketplaceData {
  padding: 0 0 20px 0;
  margin-top: 20px;

  .title {
    color: $greyTextColor;
    @include font($fontAeonik, 16px, 24px, 400);
  }

  .text {
    @include font($fontAeonik, 16px, 24px, 600);
    width: 100%;
    display: block;
    -ms-word-break: break-all;
    word-break: break-all;
  }
}

.readMoreBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0;
  cursor: pointer;

  .readMoreBtn {
    margin-right: 15px;
    cursor: pointer;
  }
}

// Connect Marketplace Block
.connectChannelWrapper {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titleBlock {
      .title {
        @include font($fontAeonik, 24px, 32px, 700);
        color: $ternaryTextColor;
      }

      .subTitle {
        @include font($fontAeonik, 21.33px, 30px, 400);
        color: $greyTextColor;
        margin-bottom: 36px;
      }
    }

    .imgBlock {
      margin-left: 15px;
      cursor: pointer;
    }
  }
}

.connectContainer {
  max-width: 300px;
  margin-top: 30px;

  .connectFromTitle {
    @include font($fontAeonik, 32px, 32px, 400);
    color: $greyTextColor;
    margin-bottom: 50px;
  }
}

.connectBtnWrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}

.connectBtn {
  max-width: 216.67px;
  min-width: 180px;
  height: 66.67px;
  padding: 0 20px !important;
  border-radius: 33.33px !important;
  color: $greyShade4;
}

.importContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .text {
    @include font($fontAeonik, 26px, 26px, 400);
    margin-top: 25px;
  }

  .btn {
    margin-top: 25px;
  }
}

.connectMessage {
  @include font($fontAeonik, 22px, 32px, 400);
  margin-top: 25px;
}
