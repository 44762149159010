@import "~styles/colors";
@import "~styles/typography";

.label-wrapper {
  display: flex;
  position: absolute;
  top: 20px;
  text-align: left;
  @include font($fontAeonik, 24px, 24px, normal);
  color: #808080 !important;
  margin-bottom: 0;
  z-index: 1;
  transition: all 0.3s;
  pointer-events: none;

  &-active {
    top: -13px;
    @include font($fontAeonik, 13px, 16px, normal);

    .info-label {
      margin-left: 10px;
      pointer-events: initial !important;
      transform: scale(0.7);
    }
  }
}

.input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 30px;
  height: 30px;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;

  margin-top: 25px;
}

.input[type="checkbox"]::before {
  content: "";
  width: 15px;
  height: 15px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: $primaryColor;
}

.input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.input[type="checkbox"]:focus {
}

.input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}
