@import "~styles/colors";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  text-rendering: optimizeLegibility;
  text-decoration-skip: objects;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
}

:focus {
  outline: none;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;

  &:hover {
    text-decoration: none !important;
  }
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  font: inherit;
  color: inherit;
  letter-spacing: inherit;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  cursor: pointer;
  -webkit-appearance: none;
}

/*placeholder customeizing*/
::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.3s;
}

::-moz-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.3s;
}

:-moz-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.3s;
}

:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
  transition: opacity 0.3s;
}

:focus::-webkit-input-placeholder {
  opacity: 0;
}

:focus::-moz-placeholder {
  opacity: 0;
}

:focus:-moz-placeholder {
  opacity: 0;
}

:focus:-ms-input-placeholder {
  opacity: 0;
}

/*autofill customeizing*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $primaryBackgroundColor inset !important;
}

::selection {
  color: $greyShade4;
  background-color: $primarySubColor;
}

/* Input type Number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

/*scrollbars customeizing*/
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $greyShade3;
}
::-webkit-scrollbar-corner {
  display: none;
}

:root {
  scrollbar-color: $greyShade3 $greyShade4;
  scrollbar-width: thin;
}
