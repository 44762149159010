@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.subscriptionContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
  padding: 0 !important;

  @include for-desktop {
    flex-direction: column;
  }
}

.infoBlock {
  width: 50%;
  padding: 35px 50px 35px 45px !important;

  @include for-desktop {
    width: 100%;
  }
}

.imgBlock {
  width: 50%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  background-image: url(../../../assets/images/auth-bg.png);
  background-position: right bottom;
  background-size: cover;

  @include for-desktop {
    width: 100%;
    height: 300px;
    border-top-right-radius: initial;
    border-bottom-left-radius: 15px;
  }
}

.infoContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include for-tablet-landscape {
    flex-direction: column;
  }
}

.planTitle {
  font-size: 32px;
  @include font($fontAeonik, 32px, 35px, 700);
  color: $ternaryTextColor;
  margin-top: 25px;
}

.planSubtitle {
  @include font($fontAeonik, 16px, 24px, 500);
  color: $greyTextColor;
  margin-bottom: 25px;
}

.checkRow {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: -10px;

  img {
    margin: 0 13px 0 15px;
  }

  div {
    color: $greyTextColor;
  }
}

.btn {
  width: 180px;
  height: 66.67px;
  border-radius: 33.33px !important;
  margin-left: 10px;

  @include for-tablet-landscape {
    margin-top: 20px;
  }
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
}
