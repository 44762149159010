@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.commingWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  opacity: 0.65;

  .commingImg {
    width: 100%;
    height: auto;
  }

  .comming {
    width: 100%;
    position: fixed;
    top: 50%;
    left: 10%;
    text-align: center;
    opacity: 0.5;
    @include font($fontAeonik, 95px, 65px, 400);
    transform: rotate(330deg);

    @include for-lg-desktop {
      @include font($fontAeonik, 65px, 65px, 400);
    }
  }
}
