@import "~styles/typography";
@import "~styles/colors";

.linkIcon {
  margin-right: 23px;
  display: flex;
  align-items: center;

  svg {
    fill: $primaryColor;
  }

  img {
    width: 19px;
  }
}

.dropdownIcon {
  display: flex;
  justify-content: center;

  svg {
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
}

.dropdownIconUp {
  svg {
    transform: rotate(-180deg);
  }
}

.sidebar {
  width: 333.33px;
  background-color: $secondaryBackgroundColor;
  color: $secondaryTextColor;
  display: flex;
  flex-direction: column;

  .createListingWrapper {
    width: 100%;
    min-height: 130px;
    background-color: $primarySubColor;
    display: flex;

    .createListingDropdownButton {
      &:hover,
      &:focus {
        background: $primarySubColor !important;
      }

      width: 100%;
      height: 100%;
      text-align: left;
      padding: 0 47px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: $secondaryBackgroundColor !important;
      font-size: 21.3px;
      border-radius: 0 !important;

      &.active {
        background: $primarySubColorActive !important;
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    overflow-y: auto;

    .show {
      pointer-events: initial !important;

      .hideCreateListingMenuButton {
        opacity: 1 !important;
      }

      .createListingMenu {
        max-height: 333px !important;
      }
    }

    .createListingDropdown {
      pointer-events: none;
      position: absolute;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .hideCreateListingMenuButton {
        height: 100%;
        opacity: 0;
        transition: opacity 0.3s;
        display: block;
        background: rgba($secondaryBackgroundColor, 0.75) !important;
      }

      .createListingMenu {
        height: 500px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background: $primarySubColor;
        max-height: 0;
        transition: max-height 0.3s;

        .link {
          display: flex;
          box-sizing: content-box;
          padding: 29px 47px;
          height: 21px;
          color: $secondaryBackgroundColor;
          text-decoration: none;

          @include font($fontAeonik, 18.66px, 18.66px);

          &:first-of-type {
            margin-top: 37px;
          }

          &:last-of-type {
            margin-bottom: 54px;
          }

          &:hover {
            background: $primarySubColorActive;
          }
        }
      }
    }
  }

  .linksList {
    margin-top: 136px;
    display: flex;
    flex-direction: column;
    flex: 1;

    @media (max-height: 900px) {
      margin-top: 56px;
    }

    .link {
      display: flex;
      align-items: center;
      min-height: 40px;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-left: 47px;

      @include font($fontAeonik, 20px, 20px);

      color: $secondaryTextColor;
      text-decoration: none;

      &.active,
      &:hover {
        color: $greyShade4;
        box-shadow: inset 5px 0 0 $blueBackgroundColor;

        svg {
          fill: #f29ca1;
        }
      }
    }
  }
}
