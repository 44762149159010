@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.controlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 30px;

  .userInfo {
    min-width: 230px;
    margin-right: 10px;

    & div:first-child {
      @include font($fontAeonik, 21.33px, 32px, 700);
      color: $ternaryTextColor;
    }

    & div:last-child {
      @include font($fontAeonik, 18.67px, 32px, 400);
      color: $greyTextColor;
    }
  }

  .btnsWrapper {
    display: flex;
    margin-top: 10px;

    .controlsWrapper {
      display: flex;
      align-items: center;

      .controlBtn {
        margin-right: 30px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }
      }

      .controlBtn.disabled {
        cursor: default;

        svg {
          path {
            stroke: $greyShade2;
          }
        }
      }
    }

    .autoreplyBtnWrapper {
      margin-left: 40px;

      .autoreplyBtn {
        max-width: 190px;
        height: 46.67px;
        font-size: 15px;
      }
    }
  }
}
