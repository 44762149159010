@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.modalContainer {
  max-width: 1000px !important;
}

.modalContent {
  padding: 30px;
  border-radius: 15px !important;
}

.header {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  margin-bottom: 20px;

  .title {
    text-align: center;
  }
}

.modalBody {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modalBtnContainer {
  width: 100%;
  text-align: end;
  margin-top: 30px;
}

.modalBtn {
  width: 100px;
  margin-left: 15px;
  padding: 0 !important;
}

.cardInfo {
  display: flex;
  margin-right: -20px;
}

.passInputContainer {
  margin-right: 20px;

  @include for-desktop {
    width: 100%;
    margin-bottom: 21px;
  }
}

.billingAddressTitle {
  margin-top: 60px;
  margin-bottom: 20px;
  font-size: 1.3rem;
  line-height: 1.2;
  font-weight: 500;
}

.streetAddressInput {
  width: 70%;
}

.floorInput {
  width: calc(30% - 20px);
  margin-left: 20px;
  margin-right: 20px;
}

.cityAndStateContainer {
  width: 70%;
  display: flex;
}

.zipCode {
  width: calc(30% - 20px);
  margin-left: 20px;
  margin-right: 20px;
}

.stateOrProvince {
  height: 66px;
}

.warnInfo {
  margin-top: 50px;
  border-radius: 12px !important;
  padding: 30px;
  background-color: $greyShade3;
  @include font($fontAeonik, 19px, normal, 400);
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 21px;
  margin-right: 20px;
  position: relative;
  border-bottom: 1px solid #555555;

  .input-wrap {
    position: relative;
    padding: 20px 0 16px;
  }

  .password-eye-wrapper {
    display: none;
    position: absolute;
    right: 8px;
    top: 4px;
    line-height: 0;

    .password-eye {
      padding: 0;
      line-height: 14px;
      background: none;
      border: none;
      outline: none;

      &:hover,
      &:active,
      &:focus,
      &:active:focus {
        background-color: transparent;
        box-shadow: none;
      }
    }
  }
}
