@import "~styles/colors";

.react-tag-input {
  border: none;
  margin-top: 15px;
} /* Wrapper */
.react-tag-input__input {
  &::placeholder {
    color: #808080 !important;
  }
} /* Inner input */
.react-tag-input__tag {
  background-color: rgba($primaryColor, 0.8);
  color: $greyShade4;
  align-items: initial;
} /* Tag wrapper */
.react-tag-input__tag__content {
  word-break: break-all;
  white-space: normal;
  display: flex;
  align-items: center;
} /* The text content within the tag */
.react-tag-input__tag__remove {
  background-color: $primaryColor;
  min-width: 2em;
  min-height: 2em;
  height: auto;

  &:before,
  &:after {
    background-color: $greyShade4;
  }
} /* The remove button & icon for a tag */
