@import "~styles/colors";
@import "~styles/typography";

.modalContainer {
  max-width: 590px !important;
}

.modalContent {
  border-radius: 15px !important;
  height: 604px;
  overflow: hidden;
}

.header {
  height: 55%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-image: url(../../../assets/images/modal-brg.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .title {
    text-align: center;
    @include font($fontAeonik, 51.33px, 21.33px, 700);
    color: $greyShade4;
  }

  .channelCloseBtn {
    position: absolute;
    top: 20px;
    right: 33px;
    width: 43.33px;
    height: 43.33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $greyShade4 !important;
    transform: rotate(45deg);
    align-self: flex-end;

    &:hover {
      border: none !important;
      background-color: $blueBackgroundColor !important;
    }
  }
}

.modalBody {
  height: 45%;
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notification {
  @include font($fontAeonik, 21.33px, 34.67px, 600);

  .firstLine {
    color: $greyShade2;
    text-align: center;
  }
  .secondLine {
    color: $greyShade1;
    text-align: center;
    margin-bottom: 45px;
  }
}

.planBtn {
  width: 100%;
  max-width: 222px;
  height: 66.67px;
  border-radius: 33.33px !important;
}
