@import "~styles/colors";
@import "~styles/typography";

.stepsWrapper {
  padding: 50px 50px 80px !important;

  .replyTitle {
    padding-bottom: 45px;

    .titleInput {
      width: 100%;
      @include font($fontAeonik, 40px, 40px, 600);
      color: $ternaryTextColor;

      &::placeholder {
        color: $greyTextColor;
      }
    }
  }
}

.filledTitle {
  color: $ternaryTextColor !important;
}

.continueBtn {
  width: 100%;
  max-width: 174.67px;
  height: 66.67px;
  border-radius: 33.33px !important;
  @include font($fontAeonik, 16px, 16px, 500);
  line-height: initial !important;
  margin-top: 54px;

  &:hover {
    border: 2px solid $borderGreyColor !important;
  }
}

.stepBodyWrapper {
  width: 85%;
  max-width: 1120px;

  .stepTitle {
    @include font($fontAeonik, 24px, 24px, 700);
    color: $ternaryTextColor;
    margin: 51px 0 74px;
  }
}
