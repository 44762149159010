@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.billingInfoContainer {
  padding: 35px 45px !important;
  margin-bottom: 21px;
}

.subscriptionContainer {
  display: flex;

  @include for-desktop {
    flex-direction: column;
  }
}

.billingAddress {
  width: 50%;

  @include for-desktop {
    width: 100%;
  }
}

.personalData {
  padding: 20px 0;

  .title {
    color: $greyTextColor;
    @include font($fontAeonik, 16px, 24px, 400);
    margin-bottom: 10px;
  }

  .text {
    @include font($fontAeonik, 16px, 24px, 600);
    margin-bottom: 5px;
  }
}

.billingCardNum {
  width: 50%;
  padding-left: 85px;
  @include font($fontAeonik, 35px, normal, 400);

  @include for-desktop {
    width: 100%;
    padding-left: 0;
  }
}

.btn {
  width: 100%;
  max-width: 234px;
  height: 64px;
  border-radius: 33.33px !important;
  margin-top: 20px;
}

.noPayments {
  width: 50%;
  @include font($fontAeonik, 28px, normal, 400);
  color: $greyTextColor;
  margin: 20px 20px 20px 0;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}
