@import "~styles/_breakpoints.scss";

.settingsContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 21px;
  gap: 27px;

  @include for-desktop {
    flex-direction: column;
    justify-content: center;
  }

  label {
    margin-bottom: 0;
  }
}

.deleteButtonContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  .btn {
    height: 64px;
    border-radius: 33.33px !important;
  }
}
