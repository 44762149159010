@import "~styles/colors";
@import "~styles/_breakpoints.scss";

.dashboard-container {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.dashboard-wrapper {
  width: calc(100% - 88px);
  position: relative;
  background: $primaryBackgroundColor;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.dashboard-content {
  padding: 63px;
  flex: 1;
  overflow: hidden;
  // display: flex;
  flex-direction: column;
  background: $ternaryBackgroundColor;
  overflow-y: auto;

  @include for-lg-desktop {
    padding: 26px;
  }
}

.header {
  position: relative;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;
  padding-top: 10px;
}

@media (max-width: 500px) {
  .profileDropDown {
    display: none;
  }

  .dashboard-wrapper {
    width: 100%;
    padding-top: 30px;
  }

  .dashboard-container {
    flex-direction: column;
    background: $primaryBackgroundColor;
  }
}
