@import "~styles/colors";
@import "~styles/typography";

.thirdStepWrapper {
  display: flex;

  .platformSwitchersWrapper {
    width: 50%;
    padding-right: 40px;

    .subtitle {
      @include font($fontAeonik, 13.33px, 13.33px, 500);
      color: $greyTextColor;
    }

    .switchersContainer {
      margin-top: 38px;
    }
  }

  .listingsWrapper {
    width: 50%;

    .selectSendBlock {
      &:first-child {
        margin: 0;
      }
    }
  }
}
