@import "~styles/colors";
@import "~styles/typography";

.firstEnterWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $primaryBackgroundColor;
  padding: 23px 47px !important;
  margin-bottom: 20px;

  .welcomeImg {
    margin-right: 20px;
  }

  .welcomeText {
    margin-right: 10px;

    div {
      @include font($fontAeonik, 16px, 25.33px, 900);
    }

    div:last-child {
      color: $greyTextColor;
      font-weight: 400;
    }
  }

  .closeBtn {
    width: 47px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    background-color: $ternaryBackgroundColor;
    margin-left: auto;
    cursor: pointer;

    img {
      transform: rotate(45deg);
    }

    &:hover {
      background-color: $blueBackgroundColor !important;
    }
  }
}
