@import "~styles/colors";
@import "~styles/typography";

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.profile-dropdown.show > .btn-success.dropdown-toggle {
  background-color: $primaryColor;
  background-image: url("../../../../assets/icons/dots-white.png");

  &:focus {
    box-shadow: none;
  }
}

.profile-dropdown {
  margin-left: 24px;

  .dropdown-toggle {
    background: $greyShade4;
    border: none;
    border-radius: 50%;

    height: 62px;
    width: 62px;

    background-image: url("../../../../assets/icons/dots.png");
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-color: $primaryColor;
      background-image: url("../../../../assets/icons/dots-white.png");
    }

    &:after {
      display: none;
    }

    &:focus {
      background-color: $primaryColor;
      box-shadow: none;
    }
  }

  .dropdown-menu {
    width: 320px;
    top: 30px !important;
    box-shadow: 0 0 10px rgba($greyShade1, 0.1);
    border: none;
    border-radius: 8px;
    padding: 10px;
  }

  .dropdown-item {
    height: 47px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    @include font($fontAeonik, 20px, 20px, medium);
    color: $greyShade1;

    &:hover {
      background-color: rgba($primaryColor, 0.1);
      color: $primaryColor;
    }
  }
}
