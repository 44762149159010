@import "~styles/colors";
@import "~styles/typography";

.replyCard {
  width: 256px;
  height: 256px;
  border: 2px solid $borderGreyColor;
  border-radius: 15px;
  padding: 23px 28px 25px;
  margin: 0 20px 20px 0;
  display: flex;
  flex-direction: column;

  .titleBclock {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    .title {
      width: 80%;
      height: 115px;
      @include font($fontAeonik, 30.67px, 38px, 700);
      color: $ternaryTextColor;
      transition: 0.5s;
      overflow: hidden;
    }

    .menu {
      width: 10%;
      cursor: pointer;
      padding-top: 10px;

      & > img {
        display: block;
        margin: 0 auto;
      }
    }
  }

  .switcherBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;

    .statusTitle {
      @include font($fontAeonik, 18.67px, 40px, 500);
      color: $blueTextColor;
    }
  }
}

.notActive {
  .title {
    color: $greyTextColor !important;
  }

  .statusTitle {
    color: $greyTextColor !important;
  }
}

/* The switcher */
.switcherContainer {
  display: flex;
  justify-content: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 31px;
  margin-bottom: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $borderGreyColor;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 4px;
  bottom: 3px;
  background-color: $primaryBackgroundColor;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $blueBackgroundColor;
}

input:focus + .slider {
  box-shadow: 0 0 1px $primaryBackgroundColor;
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
  background-color: $primaryBackgroundColor;
}

.slider.round {
  border-radius: 15.46px;
}

.slider.round:before {
  border-radius: 50%;
}

.dropdown {
  margin-left: 14px;

  & > button {
    width: 40px !important;
    height: 40px !important;
    background-position: 16.5px center !important;
  }
}
