@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.chatsContainer {
  width: 100%;
  max-width: 476px;
  min-width: 380px;
  height: 100%;
  overflow-y: scroll;
  margin-right: -5px; // scrollbar width
  scrollbar-width: thin;
}

.searchBlock {
  height: 91px;
  display: flex;
  align-items: center;
  padding-left: 66px;

  .imgWrapper {
    display: flex;
    align-items: center;
  }

  .searchInput {
    margin-left: 13px;

    input {
      &::placeholder {
        color: $greyTextColor;
        @include font($fontAeonik, 18.67px, 52.49px, 500);
      }
    }
  }

  .closeBtn {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border-radius: 50%;
    transform: rotate(45deg);
    cursor: pointer;

    &:hover {
      background-color: $primaryColor;
    }
  }
}

.emptyChats {
  text-align: center;
  margin-top: 20px;
  color: $greyTextColor;
  @include font($fontAeonik, 18.67px, 52.49px, 500);
}
