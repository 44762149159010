.editModalHeader {
  display: flex;
  justify-content: space-between;
  padding: 52px 0 27px;
}

.bodyContainer {
  margin-top: 20px;
}

.deleteBtn {
  margin-left: auto;
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .emptyText {
    margin-top: 20px;
  }

  .closeBtn {
    margin-top: 30px;
  }
}

.deleteBtnList {
  margin: 0 auto;
  margin-top: 30px;
}

.loader {
  margin-top: 0;
  margin-left: auto;
  padding-right: 45px;
}
