@import "~styles/colors";
@import "~styles/typography";

.container {
  cursor: pointer;
}

.icon {
  &:hover {
    cursor: pointer;
    opacity: 0.75;
  }
}

.title {
  @include font($fontAeonik, 13px, 13px, bold);
  color: $primaryColor;
  margin-bottom: 17px;
}
