@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .priceBlock {
    width: 100%;
    height: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .price {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-bottom: 22px;

      div:first-child {
        @include font($fontTungstenBook, 80px, 80px);
      }

      div:last-child {
        @include font($fontTungstenBook, 53.33px, normal);
      }
    }

    .term {
      width: 100%;
      text-align: center;
      color: $greyTextColor;
      padding-bottom: 21px;
      @include font($fontAeonik, 16px, 16px);
    }

    .withoutPrice {
      @include font($fontAeonik, 32px, 40px, 600);
      color: $ternaryTextColor;
    }
  }

  .planTitle {
    width: 100%;
    @include font($fontAeonik, 26.67px, 26.67px, 400);
    color: $blueTextColor;
    padding: 21px;
    text-align: center;
  }

  .benefits {
    width: 100%;
    height: 240px;
    padding: 32px 0 37px 0;

    .typeTitle {
      @include font($fontAeonik, 16px, 24px, 600);
      text-align: center;
      color: $ternaryTextColor;
      padding-bottom: 38px;
    }

    .checkRow {
      display: flex;
      align-items: center;
      align-items: baseline;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        margin: 0 13px 0 15px;
      }

      div {
        @include font($fontAeonik, 16px, 21.33px, 400);
        color: $greyTextColor;
      }
    }
  }

  .cardBtn {
    width: 100%;
    max-width: 152px;
    height: 48px;
    border-radius: 23.33px;
    margin-top: 27px;
  }
}
