@import "~styles/colors";
@import "~styles/typography";

// .box {
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   width: 300px;
//   z-index: 10000;
//   padding: 10px 10px 0 0;
// }

.box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  pointer-events: none;

  & a {
    text-decoration: underline;
  }
}

.itemEnter {
  opacity: 0;
}

.itemEnterActive {
  transition: opacity 0.5s;
  opacity: 1;
}

.itemLeaveActive {
  transition: opacity 0.5s;
  opacity: 0;
}

.positionTop {
  width: fit-content;
  max-width: 500px;
  position: absolute;
  margin: 10px auto 0 auto;
  left: 0;
  right: 0;
  pointer-events: initial;
}

.positionBottom {
  position: absolute !important;
  bottom: 0;
  right: 10px;
  pointer-events: initial;
}
