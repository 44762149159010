@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.messagesContainer {
  width: 100%;
  min-width: 820px;
  height: 940px !important;
  padding: 0 !important;
  overflow: hidden;
  display: flex;
}

.chatDetailsWrapper {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 33px 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 2px;
    height: 100%;
    background-color: $borderGreyColor;
  }
}

.dialogWrapper {
  height: 100%;
  overflow-y: scroll;
  margin-right: -40px;
  padding-right: 35px;
  scrollbar-width: thin;
}

.emptyConversation {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  @include font($fontAeonik, 24px, 24px, 500);
  color: $greyTextColor;
}

.emptyTargetDialog {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include font($fontAeonik, 24px, 24px, 500);
  color: $greyTextColor;
}

.messageTime {
  @include font($fontAeonik, 14.67px, 14.67px, 500);
  color: $greyTextColor;
  text-align: center;
  margin: 40px 0 30px;
}

.conversationLoder {
  top: 50%;
}

.messageLoader {
  margin-top: 25px;
  margin-bottom: 25px;
}
