@font-face {
  font-family: "Aeonik";
  src: url("./AeonikRegular.woff2") format("woff2"),
    url("./AeonikMedium.woff2") format("woff2"),
    url("./AeonikBold.woff2") format("woff2");
}

@font-face {
  font-family: "Aeonik Light";
  src: url("./Aeonik-Light.woff2") format("woff2"),
    url("./Aeonik-LightItalic.otf") format("otf");
}
