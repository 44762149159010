@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.billingInfoContainer {
  padding: 35px 45px !important;
}

.billingAddress {
  width: 50%;

  @include for-desktop {
    width: 100%;
  }
}

.personalData {
  padding: 20px 0;

  .title {
    color: $greyTextColor;
    @include font($fontAeonik, 16px, 24px, 400);
    margin-bottom: 10px;
  }

  .text {
    @include font($fontAeonik, 16px, 24px, 600);
    margin-bottom: 5px;
  }
}

.billingCardNum {
  width: 50%;
  padding-left: 85px;
  @include font($fontAeonik, 35px, normal, 400);

  @include for-desktop {
    width: 100%;
    padding-left: 0;
  }
}

.addBtnContainer {
  width: 100%;
  text-align: center;
}

.btn {
  height: 64px;
  border-radius: 33.33px !important;
  margin-top: 20px;
}

.noPayments {
  // width: 50%;
  @include font($fontAeonik, 28px, normal, 400);
  color: $greyTextColor;
  margin: 20px 0 20px 0;
}

.loaderContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.cardHeader {
  @include font($fontAeonik, 18px, 18px, 900);
  color: #000;
}

.cardContainer {
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #d5d5d5;
  width: 80%;
  max-width: 500px;

  .cardInfo {
    @include font($fontAeonik, 16px, 18px, 400);
    color: #000;
    padding-top: 10px;
  }

  .cardLineContainer {
    display: flex;
    justify-content: center;
    margin: 20px 0;

    .cardLine {
      width: 100%;
      margin: 0 20px;
      height: 1px;
      background-color: #d5d5d5;
    }
  }

  .cardButtonsContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0px 20px 20px 20px;

    .cardBtn {
      margin-left: 10px;
      padding: 5px;
      @include font($fontAeonik, 16px, 18px, 900);
      &:hover {
        cursor: pointer;
      }
    }

    .cardManageBtn {
      color: $blueTextColor;
    }

    .cardDeleteBtn {
      color: $alertColor;
    }
  }
}

.cardHeaderContainer {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px;
}

.cardBrandIcon {
  width: 60px;
  margin-top: -10px;
}
