@import "~styles/colors";
@import "~styles/typography";

.info-tooltip > .tooltip-inner {
  max-width: 900px !important;
  text-align: left;
  padding: 26px 19px;
  background-color: $greyShade4;
  @include font($fontAeonik, 13px, 13px, normal);
  font-size: 13px !important;
  // TODO: move color to variable
  color: #7d7d7d;
  box-shadow: 0 0 8px rgba(#7d7d7d, 0.15);
  border-radius: 10px;
  opacity: 1;
  margin-bottom: 9px;
}

.info-tooltip.show {
  opacity: 1 !important;
}

.info-tooltip > .bs-tooltip-auto[x-placement^="top"] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top: none !important;
}

.info-tooltip > .bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right: none !important;
}
