@import "~styles/colors";
@import "~styles/typography";

.container {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  // background: #fff;
  // border-radius: 4px;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
  // border: solid 1px $primaryBorderColor;
  // width: 384px;
  // height: 196px;
  // margin-top: 15%;
}

.title {
  // @include font(, 24px, 24px, 600);
  // color: $mainText;
  margin-bottom: 30px;
}

.description {
  // @include font($fontWorkSans, 18px, 18px);
  // color: $mainText;
}
