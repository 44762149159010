$greyShade1: #000;
$greyShade2: #959595;
$greyShade3: #ebebeb;
$greyShade4: #fff;

$primaryColor: #5f7fd3;
$primarySubColor: #9fc2e4;
$primarySubColorActive: #94b6da;

$primaryBackgroundColor: $greyShade4;
$secondaryBackgroundColor: #34497f;
$ternaryBackgroundColor: #f5f5f5;
$blueBackgroundColor: #5f7fd3;
$greenBackgroundColor: #62b96a;

$primaryTextColor: #262626;
$secondaryTextColor: #a8c0ff;
$ternaryTextColor: $greyShade1;
$greyTextColor: $greyShade2;
$blueTextColor: #5f7fd3;
// new colors
$greyText: #656565;

$inputLabelColor: #7d7d7d;
$borderGreyColor: $greyShade3;
$highlitedMessageColor: #f1f1f1;

$alertColor: #e85656;
$greenTextColor: #62b96a;
