@import "~styles/colors";
@import "~styles/typography";

.recommendedTitle {
  color: $blueTextColor !important;
}

.recommendedBtn {
  max-width: 126px !important;
}
