@import "~styles/colors";
@import "~styles/typography";

.container {
  position: relative;
}

.icon {
  position: absolute;
  top: 12px;
  left: 20px;
}

.input {
  background-color: #eceffa !important;
  border-radius: 23px !important;
  padding: 13px 20px 13px 60px !important;
  height: 46px !important;
  width: 100%;
  @include font($fontAeonik, 16px, 16px);
  font-size: 16px !important;
  font-weight: 900 !important;
  color: $blueTextColor !important;

  &:placeholder {
    color: $blueTextColor !important;
    font-size: 16px !important;
  }

  &::-webkit-input-placeholder {
    color: $blueTextColor !important;
    font-size: 16px !important;
  }

  &:active,
  &:focus {
    background-color: #eceffa;
  }
}

.inputContainer {
  border-bottom: none;
  margin: 0;
}
