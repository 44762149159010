@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.container {
  max-width: 1800px;
  border-radius: 15px;
  background-color: $primaryBackgroundColor;
  padding: 53px 66px;
  border-radius: 15px;
}
