@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.passwordContainer {
  width: 100%;
  padding: 35px 45px 59px 45px !important;

  @include for-desktop {
    width: 100%;
    margin-bottom: 21px;
  }
}

.passInputContainer {
  margin: 40px 0;
  width: 80%;
}

.inputContainer {
  // TODO: delete this whole class after replacement all inputs with custom component
  border-bottom: 1px solid $greyTextColor;
  margin: 40px 0;
  width: 80%;

  label {
    @include font($fontAeonik, 13.33px, normal, 500);
    color: $greyTextColor;
    display: block;
  }

  input,
  select {
    width: 100%;
    height: 30px;
    margin: 10px 0 15px 0;
    @include font($fontAeonik, 35px, normal, 400);
    letter-spacing: 2px;
    cursor: pointer;

    &::placeholder {
      @include font($fontAeonik, 24px, 24px, 400);
      color: $greyTextColor;
    }
  }

  select {
    width: 95%;
    @include font($fontAeonik, 24px, normal, 400);
    padding-right: 10px;
  }
}

.alert {
  border-bottom: 1px solid $alertColor;

  label {
    color: $alertColor;
  }

  input {
    color: $alertColor;
  }
}

.btn {
  width: 100%;
  max-width: 149.33px;
  height: 66.67px;
  border-radius: 33.33px !important;
  margin-top: 13px;
  margin-bottom: 14px;
}
