@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.screenContainer {
  display: flex;

  @include for-desktop {
    flex-wrap: wrap;
  }

  .profileInfo {
    width: 100%;
    max-width: 329px;
    background-color: $primaryBackgroundColor;
    padding: 47px 33px 41px 33px !important;
    margin-right: 27px;

    @include for-desktop {
      margin-bottom: 20px;
      max-width: 100%;
      margin-right: 0;
    }
  }

  .profileSubscription {
    display: flex;
    flex-grow: 2;
    overflow: hidden;
    background-color: $primaryBackgroundColor;
    padding: 0 !important;

    @include for-desktop {
      flex-direction: column;
    }
  }
}

.personalData {
  padding: 20px 0;

  .title {
    color: $greyTextColor;
    @include font($fontAeonik, 16px, 24px, 400);
  }

  .text {
    @include font($fontAeonik, 16px, 24px, 600);
  }
}

.subscriptionInfo {
  width: 50%;
  padding: 35px 65px;

  @include for-desktop {
    width: 100%;
  }

  @include for-lg-desktop {
    padding: 35px;
  }

  .title {
    @include font($fontAeonik, 22px, 23px, 700);
    padding-bottom: 35px;
    color: $ternaryTextColor;
  }
}

.subscriptionImage {
  width: 50%;
  background-image: url(../../assets/images/auth-bg.png);
  background-position: right;
  background-size: cover;

  @include for-desktop {
    width: 100%;
    height: 300px;
    background-position: right bottom;
  }
}

.subscriptionType {
  padding: 55px 0;

  .typeTitle {
    font-size: 32px;
    @include font($fontAeonik, 32px, 35px, 700);
    color: $ternaryTextColor;
    padding-bottom: 35px;
  }

  .checkRow {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    img {
      margin: 0 13px 0 15px;
    }

    div {
      color: $greyTextColor;
    }
  }
}

.subscriptionData {
  display: flex;
  padding: 33px 0;

  .block {
    width: 50%;

    .subtitle {
      @include font($fontAeonik, 13.33px, 13.33px, 500);
      color: $greyTextColor;
      margin-bottom: 20px;
      margin-right: 5px;
    }

    .date {
      @include font($fontAeonik, 21.33px, 21.33px, 400);
      color: $ternaryTextColor;
      margin-right: 5px;
    }

    .noPayments {
      @include font($fontAeonik, 21.33px, 21.33px, 400);
      color: $greyTextColor;
      margin-right: 5px;
    }
  }
}

.subscriptionBtn {
  width: 100%;
  max-width: 238.67px;
  min-width: 180px;
  margin-top: 53px;
  height: 66.67px;
  border-radius: 33.33px !important;
  color: $greyShade4;
  text-align: center;
  @include font($fontAeonik, 21.33px, 21.33px, 400);
}

.subscriptionLoader {
  margin-top: 47px;
}
