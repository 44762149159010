@import "~styles/colors";
@import "~styles/typography";

.repliesBlock {
  padding: 20px 47px 13px 47px !important;
  margin-bottom: 27px;
}

.titleBlock {
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 30px;

  .sectionTitle {
    @include font($fontAeonik, 21.33px, 23px, 700);
    color: $ternaryTextColor;
  }

  .btn {
    width: 100%;
    max-width: 156.67px;
    height: 46.67px;
    border-radius: 23.33px;
    @include font($fontAeonik, 16px, 16px, 500);

    &:hover {
      border: 2px solid $borderGreyColor !important;
    }
  }
}

.savedRepliesBlock {
  display: flex;
  flex-wrap: wrap;
}

.createReplyTitle {
  @include font($fontAeonik, 30.67px, 40px, 700);
  color: $greyTextColor;
}

.createBtn {
  width: 100%;
  max-width: 108px;
  height: 46.67px;
  border-radius: 23.33px !important;
  border: 2px solid $borderGreyColor !important;
  @include font($fontAeonik, 16px, 16px, 500);
  line-height: initial !important;
  margin-top: auto;

  &:hover {
    border: none !important;
  }
}

.loader {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
