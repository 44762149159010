@import "~styles/colors";
@import "~styles/typography";

.overlay {
  background-color: $ternaryBackgroundColor;
  padding: 8px 13px !important;
  border-radius: 5px;
  color: $greyTextColor;
  @include font($fontAeonik, 18px, 18px, 400);
}
