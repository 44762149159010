@import "~styles/colors";
@import "~styles/typography";

.modalContainer {
  max-width: 960px !important;
}

.modalContent {
  border-radius: 15px !important;
  padding: 40px 75px;
}

.title {
  @include font($fontAeonik, 24px, 45.49px, 800);
  color: $greyShade1;
}

.subtitle {
  @include font($fontAeonik, 18.67px, 21.33px, 400);
  color: $greyShade2;
}

.controls {
  display: flex;
  gap: 15px;
  margin: 50px 0;

  .btn {
    height: 66.67px;
    min-width: 150px;
    border-radius: 33.33px !important;
    line-height: initial !important;

    &.active {
      background-color: $primaryColor !important;
      color: $greyShade4 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
}

.content {
  width: 100%;
}

// domestic step
.domesticTitle {
  padding: 20px 0;
  border-top: 1px solid $borderGreyColor;
  border-bottom: 1px solid $borderGreyColor;

  & > div:first-child {
    @include font($fontAeonik, 24px, 45.49px, 800);
    color: $greyShade1;
  }

  & > div:last-child {
    @include font($fontAeonik, 18.67px, 21.33px, 400);
    color: $greyShade2;
  }
}

.domesticContent {
  & .channel {
    display: flex;
    align-items: center;
    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid $borderGreyColor;

    & .logo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    & .btn {
      height: 50px;
      margin-left: auto;
      pointer-events: none;
    }
  }
}
