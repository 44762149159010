@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.selectNewPlanScreen {
  .title {
    @include font($fontAeonik, 24px, 32px, 700);
    color: $ternaryTextColor;
    margin-bottom: 14px;
  }

  .subtitle {
    @include font($fontAeonik, 21.33px, 40px, 400);
    color: $greyTextColor;
    padding-bottom: 36px;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 81px 0;

  .card {
    max-width: 250px;
    border: 1px solid #dbdbdb;
    margin: 0 14px 20px 0;
    padding: 32px 28px !important;

    &:last-child {
      margin-right: 0;
    }
  }
}
