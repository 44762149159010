.modalContainer {
  max-width: 500px !important;
}

.modalContent {
  padding: 30px;
  border-radius: 15px !important;
}

.header {
  display: flex;
  justify-content: center;
  padding: 0 20px;
  margin-bottom: 20px;

  .title {
    text-align: center;
  }
}

.modalBody {
  display: flex;
  justify-content: center;
}

.modalBtn {
  width: 100px;
  margin: 0 15px 0 0;
  padding: 0 !important;
}
