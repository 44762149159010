@import "~styles/colors";
@import "~styles/typography";

.button {
  height: 46px;
  border: none !important;
  border-radius: 23px !important;
  background: $blueBackgroundColor !important;
  color: $greyShade4;
  @include font($fontAeonik, 16px, 16px, 400);
  transition: 0s !important;
  padding: 0 40px !important;

  &:hover {
    background: $primaryBackgroundColor !important;
    color: $greyShade1 !important;
    box-shadow: 0 0 0 2px $borderGreyColor !important;
    outline: none;
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
    background: $blueBackgroundColor !important;
  }

  &:disabled {
    background: $greyShade2 !important;
    cursor: initial;
    pointer-events: none;
    color: $greyShade4;
  }

  svg {
    margin-top: -2px;
    margin-right: 10px;
  }
}

.transparent {
  background: transparent !important;
  color: $ternaryTextColor !important;
  @include font($fontAeonik, 16px, 16px, 400);

  &:hover {
    cursor: pointer;
    background: $blueBackgroundColor !important;
    color: $greyShade4 !important;
    border-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    background: $blueBackgroundColor !important;
  }
}

.round {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 43px;
  height: 43px;
  background: $greyShade4 !important;
  box-shadow: 0 0 0 2px inset $borderGreyColor !important;
  border: none !important;
  border-radius: 50% !important;
  padding: 0 !important;

  &:hover {
    background: $greyShade4 !important;
    box-shadow: 0 0 0 2px inset $primaryColor !important;
    border: none !important;

    & svg {
      & path,
      line {
        stroke: $primaryColor;
      }
    }
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: $borderGreyColor !important;
    background: $primarySubColorActive !important;
  }

  svg {
    margin: 0;
  }
}

.alert {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 46.35px;
  background: $primaryBackgroundColor !important;
  border: 3px solid $borderGreyColor !important;
  border-radius: 50%;
  color: $alertColor !important;

  &:hover {
    background: $alertColor !important;
    color: $primaryBackgroundColor !important;
    box-shadow: none !important;
    border-color: transparent !important;
    border: none !important;
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none !important;
    box-shadow: none !important;
    background: $alertColor !important;
    color: $primaryBackgroundColor !important;
  }
}

.primary {
  background: $primaryColor !important;
  color: $greyShade4 !important;
  @include font($fontAeonik, 16px, 16px, 600);
  line-height: 16px !important;

  &:hover {
    cursor: pointer;
    background: $primaryBackgroundColor !important;
    color: $primaryTextColor !important;
    border-color: transparent !important;
    border: none !important;
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    background: $blueBackgroundColor !important;
  }
}

.secondary {
  background: $greyShade4 !important;
  color: $greyShade1 !important;
  @include font($fontAeonik, 16px, 16px, 600);
  line-height: 16px !important;
  box-shadow: 0 0 0 2px inset $borderGreyColor !important;

  &:hover {
    cursor: pointer;
    background: $blueBackgroundColor !important;
    color: $greyShade4 !important;
    border-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
  }

  &:focus,
  &:active,
  &:active:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
    background: $greyShade4 !important;
    color: $greyShade1 !important;
    box-shadow: 0 0 0 2px $borderGreyColor !important;
  }
}

.loading {
  pointer-events: none;
}

.loader {
  margin: 0 !important;
}
