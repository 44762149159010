@import "~styles/colors";
@import "~styles/typography";

.modalContainer {
  width: 470px !important;
}

.modalContent {
  padding: 40px;
  border-radius: 15px !important;
}

.header {
  text-align: center;
  @include font($fontAeonik, 21.33px, 21.33px, 800);
  color: $ternaryTextColor;
  padding-bottom: 25px;
  margin-bottom: 15px;
}

.modalBody {
  padding: 15px 0 0;
}

.halfInputs {
  display: flex;
  justify-content: space-between;

  & > div {
    width: 45%;
  }
}

.btnsBlock {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  * {
    box-sizing: border-box;
  }

  .saveBtn {
    width: 100%;
    max-width: 160px;
    height: 45px;
    border-radius: 33.33px !important;
    margin-right: 10px;
    @include font($fontAeonik, 18px, 18px, 400);
    padding: 0 !important;
  }

  .cancelBtn {
    width: 100%;
    max-width: 110px;
    height: 45px;
    border: 2px solid $borderGreyColor !important;
    border-radius: 33.33px !important;
    @include font($fontAeonik, 18px, 18px, 400);
    padding: 0 !important;

    &:hover {
      border: none !important;
    }
  }
}
