@import "~styles/colors";
@import "~styles/typography";

.fieldsBlock {
  .inputsRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;

    & > div {
      max-width: 515px !important;
      margin-right: 95px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.messageBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  border: none;
  @include font($fontAeonik, 18px, 24px, 400);
  color: $greyTextColor;

  & label {
    margin-bottom: 21px;
  }

  & textarea,
  input {
    width: 100%;
    height: 385px;
    border: 1px solid $greyShade1;
    border-radius: 15px;
    resize: none;
    padding: 49px 100px 49px 33px;
    color: $greyTextColor;
  }

  & textarea {
    color: $primaryTextColor;
  }
}

.shortcutsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
