@import "~styles/colors";
@import "~styles/typography";

.navbar {
  background: $greyShade4;
  height: 130px;
  padding: 0 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .titleContainer {
    display: flex;
    align-items: center;

    .btnBack {
      background-image: url(../../../assets/icons/arrow-left-grey.png) !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      flex-shrink: 0;
      margin-right: 15px;
    }

    .title {
      @include font($fontAeonik, 40px, 40px);
      color: $greyShade1;
    }
  }

  .profile {
    display: flex;
    align-items: center;

    .avatar {
      width: 62px;
      height: 62px;
      border-radius: 50%;
      background: $greyShade2;
      overflow: hidden;
      flex-shrink: 0;
    }

    .avatarImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .name {
      @include font($fontAeonik, 21.33px, 21.33px);
      margin-left: 20px;
    }

    .profileButton {
      margin-left: 53px;

      &:hover {
        border: none !important;
      }
    }
  }
}
