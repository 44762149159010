@import "~styles/colors";

.sortIcon {
  margin-left: 10px;
}

.btn {
  margin-right: 13px;
}

.alertBtn {
  &:hover {
    box-shadow: 0 0 0 2px inset $alertColor !important;

    & svg {
      path {
        stroke: $alertColor;
      }
    }
  }
}

.statusText {
  align-self: flex-end;
  line-height: 15px !important;
}

.active,
.inactive {
  margin-right: 7px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $greenTextColor;
  align-self: flex-end;
}

.inactive {
  background: $alertColor;
}

.circle {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 3px solid #ccc;
}

.channelLogo {
  width: 40px;
  height: 40px;
  margin-right: 13px;
  border-radius: 50%;
  overflow: hidden;

  & > img {
    width: 40px;
  }
}

.editListing {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;

  &:hover {
    cursor: pointer;

    & svg {
      path {
        stroke: $primaryColor;
      }
    }
  }
}
