@import "~styles/colors";
@import "~styles/typography";

.controls {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 50px 0;

  .btn {
    height: 66.67px;
    min-width: 150px;
    border-radius: 33.33px !important;
    line-height: initial !important;
  }
}

.navigation {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;

  & .item {
    width: 100%;
    max-width: 60px;
    height: 5px;
    border-radius: 50px;
    background-color: $greyShade3;
  }

  & .item.passed {
    background-color: $primaryColor;
  }
}
