@import "~styles/typography";
@import "~styles/colors";

.dateTime {
  margin: 47px;

  .time {
    @include font($fontTungstenBook, 33.33px, 33.33px);
    color: $greyShade4;
  }

  .date {
    @include font($fontAeonikLight, 18.67px, 18.67px);
    color: $secondaryTextColor;
  }
}
