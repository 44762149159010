@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.bottomControlsContainer {
  display: flex;
  padding: 10px;
  width: 100%;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 0.7px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $borderGreyColor;
  }

  .textAreaBlock {
    width: 100%;
    height: 133.33px;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 27px;
    border-radius: 15px;
    border: 1px solid $borderGreyColor;
    margin-right: 15px;
    overflow: hidden;

    textarea {
      width: 93%;
      @include font($fontAeonik, 18.67px, 28px, 400);
      color: $ternaryTextColor;
      resize: none;

      &::placeholder {
        color: $greyTextColor;
      }
    }

    .sendImgBlock {
      height: fit-content;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  .btnsBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;

    & div {
      cursor: pointer;
    }

    & div:first-child {
      margin-bottom: 30px;
    }
  }
}

.fileContainer {
  position: relative;

  .fileIndicator,
  .remove {
    position: absolute;
    top: -13px;
    left: -16px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $primarySubColor;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font($fontAeonik, 18px, 18px, 400);
    color: $ternaryTextColor;
  }

  .remove {
    top: -20px;
    left: 0px;
    width: 15px;
    height: 15px;
    background-color: $alertColor;
    color: $primaryBackgroundColor;

    & img {
      filter: invert(100%);
      transform: rotate(45deg);
    }

    &:hover {
      transform: scale(1.5);
    }
  }
}
