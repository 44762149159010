@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.addressContainer {
  width: 100%;
  padding: 35px 45px !important;

  @include for-desktop {
    width: 100%;
    margin-bottom: 21px;
  }

  .titleBlock {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .btn {
    width: 100%;
    max-width: 156.67px;
    height: 46.67px;
    border: 2px solid $borderGreyColor !important;
    border-radius: 23.33px;
    @include font($fontAeonik, 16px, 16px, 600);
    margin-top: -10px;
    padding: 0 !important;

    &:hover {
      border: none !important;
    }
  }

  .noAddresses {
    margin-bottom: 10px;
    @include font($fontAeonik, 22px, 22px, 500);
  }
}

.loaderContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timezonesContainer {
  width: 80%;
}
