@import "~styles/colors";
@import "~styles/typography";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.itemsCount {
  @include font($fontAeonik, 24px, 24px, normal);
  color: $greyShade1;
  text-align: left;
  display: flex;
}

.infoIcon {
  margin-left: 13px;
}
