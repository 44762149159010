@import "~styles/colors";
@import "~styles/typography";

.cardContainer {
  display: flex;
  align-items: center;

  &:nth-child(1) {
    .card {
      background-color: #7699f3 !important;
      border: 1px solid #34497f !important;
      position: relative;

      &::after {
        content: "Default";
        position: absolute;
        right: -85px;
        top: 50%;
        transform: translate(0%, -50%);
        @include font($fontAeonik, 13.33px, normal, 500);
        color: $greyTextColor;
      }
    }
  }
}

.card {
  display: block;

  border-radius: 10px;
  border: 1px solid #34497f;
  background-color: white;
  cursor: move;
  padding: 10px;
  margin-bottom: 3px;
  line-height: 24px;
}

.deleteBtn {
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    transform: scale(1.1);
  }
}
