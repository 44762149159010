@import "~styles/colors";
@import "~styles/typography";

.integrationCard {
  padding: 33px 33px 0 !important;
  @include font($fontAeonik, 21.33px, 21.33px, 500);
}

.nameRow {
  display: flex;
  align-items: center;
  padding-bottom: 25px;

  .marketplaceLogo {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 13px;
    background-color: $blueBackgroundColor;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  .marketplaceName {
    color: $greyShade1;
  }
}

.cardRow {
  display: flex;
  justify-content: space-between;
  color: $greyShade2;
  padding: 25px 0;

  .title {
    color: $greyShade2;
    margin-right: 10px;
  }
}

.informationBlock {
  display: flex;
  align-items: center;

  .separator {
    width: 1px;
    height: 28px;
    margin: 0 14px;
    background-color: $borderGreyColor;
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  .btn {
    width: 160px;
    height: 53.33px;
    padding: 0 20px !important;
    border-radius: 26.67px !important;
  }

  .disconnectBtn {
    border: 2px solid $borderGreyColor !important;

    &:hover {
      border: none !important;
    }
  }
}

.isConnectedBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .isConnectedBulb {
    width: 13.33px;
    height: 13.33px;
    border-radius: 50%;
  }

  .cardConnected {
    background-color: $greenTextColor;
  }

  .cardNotConnected {
    background-color: $alertColor;
  }
}

.cardUnavailable {
  pointer-events: none;

  & img {
    filter: saturate(0);
  }

  .marketplaceName {
    color: $greyShade2;
  }

  svg {
    fill: $greyShade2 !important;
  }

  .isConnectedBulb {
    background-color: $greyShade3 !important;
  }

  button {
    background-color: $greyShade3 !important;
    border: none !important;
    pointer-events: none;
    color: $greyShade2 !important;
  }
}
