@import "~styles/colors";
@import "~styles/typography";

.contentRowLine {
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: 0.7px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $borderGreyColor;
  }
}

.sectionTitle {
  @include font($fontAeonik, 21.33px, 23px, 700);
  padding-bottom: 29px;
  color: $ternaryTextColor;
}
