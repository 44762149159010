@import "~styles/colors";
@import "~styles/typography";
@import "~styles/_breakpoints.scss";

.chatContainer {
  height: 115px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 47px;
  cursor: pointer;

  .userInfoWrapper {
    display: flex;

    .nameWrapper {
      width: 80%;
      margin-left: 19px;

      & div {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      & div:first-child {
        @include font($fontAeonik, 16px, 29.33px, 700);
        color: $greyShade1;
      }

      & div:last-child {
        @include font($fontAeonik, 16px, 29.33px, 400);
        color: $greyTextColor;
        width: 100%;
        max-width: 150px;
      }
    }
  }

  .timeWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;

    & div:first-child {
      @include font($fontAeonik, 13.33px, 13.33px, 500);
      color: $greyTextColor;
      white-space: nowrap;
    }

    & div:last-child {
      margin-top: 10px;
      text-transform: uppercase;
    }
  }
}

.avatarWrapper {
  flex-shrink: 0;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .withoutAvatar {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include font($fontAeonik, 21.24px, 21.24px, 400);
    color: $greyShade4;
  }
}

.channelLogoWrapper {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $blueBackgroundColor;

  & img {
    width: 100%;
    height: 100%;
    vertical-align: baseline;
  }
}

.unreadChat {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $blueBackgroundColor;
    top: 50%;
    left: 27px;
    transform: translate(-50%, -50%);
  }
}

.currentChat {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 3.33px;
    height: 62px;
    background-color: $blueBackgroundColor;
    left: 1px;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
