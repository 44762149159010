@import "~styles/colors";
@import "~styles/typography";
@import "~styles/breakpoints";

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 27px;

  @media (max-width: $lg) {
    grid-template-columns: 1fr;
    row-gap: 27px;
  }
}

.panel {
  padding: 34px 45px !important;
}

.panelTitle {
  @include font($fontAeonik, 21px, 21px, bold);
  color: $greyShade1;
}

.galleryContainer {
  height: 709px;
  margin: 34px -45px 0;
  position: relative;
  background: $greyShade3;

  .galleryView {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .uploadButton {
    position: absolute;
    bottom: 34px;
    left: 34px;
  }

  .navButtons {
    position: absolute;
    bottom: 34px;
    right: 34px;
    display: flex;

    .navButton {
      width: 47px;
      height: 47px;
      border-radius: 50% !important;
      padding: 0 !important;
      background: $greyShade4 !important;

      &:first-of-type {
        margin-right: 6px;
      }
    }
  }
}

.galleryPreviewContainer {
  margin-top: 34px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 21px;
  row-gap: 33px;

  .galleryPreview {
    position: relative;
    width: 107px;
    height: 107px;
    border-radius: 15px;
    overflow: hidden;

    &.selected {
      box-shadow: 0 0 0 2px $primaryColor;
    }

    &:hover {
      cursor: pointer;

      .removePreviewButton {
        display: block;
        padding: 0 !important;
      }
    }
  }

  .photoPreview {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .addImageButton {
    width: 107px;
    height: 107px;
    border-radius: 15px;
    background: $greyShade4 !important;
    border: 1px solid $greyShade3 !important;
  }

  .removePreviewButton {
    display: none;
    justify-content: center;
    align-items: center;
    width: 52px !important;
    height: 52px !important;
    position: absolute;
    left: calc(50% - 26px);
    top: calc(50% - 26px);
    border-radius: 50% !important;
    background: $greyShade4 !important;
    padding: 0 !important;

    &:hover {
      border: 3px solid $alertColor !important;
      box-shadow: none !important;
    }

    &:focus,
    &:active,
    &:active:focus {
      background: $alertColor !important;
      opacity: 0.5;
    }
  }
}

.hint {
  margin-top: 34px;
  padding-top: 34px;
  border-top: 1px solid $greyShade3;
  @include font($fontAeonik, 21px, 21px, normal);
  color: $greyShade2;
  text-align: center;
}

.formContainer {
  margin-top: 34px;
  margin-bottom: 40px;
  padding-top: 34px;
  border-top: 1px solid $greyShade3;
}

.field {
  margin-bottom: 60px;
}

.priceWrapper {
  position: relative;

  .priceInfo {
    position: absolute;
    right: 10px;
    top: 25px;
  }
}

.controls {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 50px 0;

  & .btn {
    height: 66.67px;
    min-width: 150px;
    border-radius: 33.33px !important;
    line-height: initial !important;

    &:first-child {
      margin-right: auto;
    }
  }
}

.imgLoader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emptyInput {
  color: $greyTextColor !important;
}
